// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as Turbo from "@hotwired/turbo"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "src/jquery"
import "src/bootstrap"
import "src/datatables"
import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import "bootstrap-daterangepicker"
import "dropzone"
import ClipboardJS from "clipboard"
import Plyr from "plyr"

import "../theme"
import "../hr"
import "../checklists"
import "../safety"
import Sortable from "sortablejs"
import "../controllers"

import "daterangepicker"


const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

document.addEventListener("DOMContentLoaded", function () {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
});

import "@nathanvda/cocoon"

Dropzone.autoDiscover = false;
// Dropzone.Dropzone.autoDiscover = false;

// make clipboardjs available to stimulus controllers
window.ClipboardJS = ClipboardJS

// make plyr available to stimulus controllers
window.Plyr = Plyr

// make select2 available to stimulus controllers
//window.Select2 = Select2

// make sortablejs available to stimulus controllers
window.Sortable = Sortable

Rails.start()
window.Rails = Rails

// Turbolinks.start()
Turbo.start()
ActiveStorage.start()
