import { Controller } from "@hotwired/stimulus"
import { DateRangePicker } from 'daterangepicker'
import moment from 'moment'

// Connects to data-controller="client-app"
export default class extends Controller {
  connect() {
    console.log('client-app connected')
    console.log(moment().format('MMMM Do YYYY, h:mm:ss a')) // check if moment is working

    this.initStartDatePicker(moment());
    this.initEndDatePicker(moment().add(1, 'days'));
  }

  initStartDatePicker = () => {
    $('.start-date').daterangepicker({
      autoApply: true,
      autoUpdateInput: false,
      singleDatePicker: true,
      showDropdowns: false,
      // TODO: add a max date for the HR year
      locale: {
        format: 'DD/MM/YYYY',
        firstDay: 1,
      },
    }, (chosen_date) => {
      if (chosen_date.isValid()) {
        $('.start-date').val(chosen_date.format('DD/MM/YYYY'));
        $('.end-date').data('daterangepicker').remove();
        this.initEndDatePicker(chosen_date);
        $('.end-date').val(chosen_date.format('DD/MM/YYYY')); // Set the end date to the chosen start date
      }
    });
  }

  initEndDatePicker = (minDate) => {
    $('.end-date').daterangepicker({
      autoApply: true,
      autoUpdateInput: false,
      singleDatePicker: true,
      showDropdowns: false,
      minDate: minDate,
      locale: {
        format: 'DD/MM/YYYY',
        firstDay: 1,
      },
    }, (chosen_date) => {
      if (chosen_date.isValid()) {
        $('.end-date').val(chosen_date.format('DD/MM/YYYY'));
        $('.start-date').data('daterangepicker').remove();
        this.initStartDatePicker(moment());
      }
    });
  }
}
