import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="datahub-membership"
export default class extends Controller {
  connect() {
  }

  addUser(event) {
    console.log(event.target.dataset.user)
    const userDetails = JSON.parse(event.target.dataset.user)
    console.log(userDetails)

    event.preventDefault()

    fetch('/team/users.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user: userDetails})
    })
    .then(response => response.json())
    .then(data => {
      // Handle response data
      console.log(data)
      if (data.error) {
        alert(data.error)
      }
    })
    .catch(error => {
      // Handle error
      console.error(error)
    })

  }
}
