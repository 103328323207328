import { co } from "@fullcalendar/core/internal-common";
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hpa-datatable"
export default class extends Controller {
  connect() {
    console.log("hpa_datatable_controller")
    // $(function() {
      $('.hpa-datatable').each(function() {

          let dt = $(this).dataTable({
            destroy: true,
            dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
            pagingType: "simple",
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            language: {
              emptyTable: $(".dtable").data("empty-table"),
              info: $(".table").data("info"),
              infoEmpty: $(".table").data("info-empty"),
              infoFiltered: $(".table").data("info-filtered"),
              lengthMenu: 'Show _MENU_',
              zeroRecords: $(".table").data("zero-records"),
              paginate: {
                previous: "<i class='bi bi-chevron-left'></i>",
                next: "<i class='bi bi-chevron-right'></i>"
              }
            },
            columnDefs: [
              { visible: false, targets: 1 },
              { orderable: false, targets: -1 },
              { className: "justify-content-end d-flex", targets: -1 }
            ],
            drawCallback: function () {
              $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
              $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
            },
            responsive: true,
            initComplete: function () {
              $(".table thead th:last-child").removeClass("d-flex justify-content-end");
              $(".dataTables_info").addClass("pt-0");
            }

          });
          window.dt = dt;
          // unload datatable when turbolinks visit
          $(document).on('turbolinks:before-visit', function () {
            dt.fnDestroy();
          });
          $(this).on('click', 'tbody tr', function () {
            console.log("hpa-datatable")
            var tr = $(this).closest('tr');
            var row = dt.row(tr);

            if (row.child.isShown()) {
              // This row is already open - close it
              row.child.hide();
              tr.removeClass('shown');
            }
            else {
              // Open this row
              row.child(format(row.data())).show();
              tr.addClass('shown');
            }
          });
          function format (rowData) {
            return '<div>' + rowData[2] + '</div>'; // Assuming action.name is in the 4th column
          }

      });
    // });
  }
}
