import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const map_select = this.element.querySelector("#map-select-wrapper")
    const pdf_upload = this.element.querySelector("#pdf-upload-wrapper")

    const attachmentSelect = this.element.querySelector("#attachment_type")
    console.log(attachmentSelect.value)

    if (attachmentSelect.value == "map") {
      map_select.style.display = "block"
    } else if (attachmentSelect.value == "pdf") {
      pdf_upload.style.display = "block"
    } else if (attachmentSelect.value == "editable_document") {
      map_select.style.display = "none"
      pdf_upload.style.display = "none"
    }

    attachmentSelect.addEventListener("change", function () {
      if (attachmentSelect.value === "map") {
        map_select.style.display = "block"
        pdf_upload.style.display = "none"
      } else if (attachmentSelect.value === "pdf") {
        map_select.style.display = "none"
        pdf_upload.style.display = "block"
      } else if (attachmentSelect.value === "editable_document") {
        map_select.style.display = "none"
        pdf_upload.style.display = "none"
      }
    })
  }
}
