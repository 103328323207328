import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["consultantFormTypeSelect", "selectFields", "ynnaFields", "textBoxFields", "hpaSelect", "hpaDocument", "swpSelect", "swpDocument", "raSelect", "raDocument", "trainingSelect", "trainingDocument"]

  connect() {
    this.hideAllFields();
    this.showFields();
  }

  hideAllFields() {
    $(this.selectFieldsTarget).hide();
    $(this.ynnaFieldsTarget).hide();
    $(this.textBoxFieldsTarget).hide();
    $(this.hpaDocumentTarget).hide();
    $(this.swpDocumentTarget).hide();
    $(this.raDocumentTarget).hide();
    $(this.trainingDocumentTarget).hide();
  }

  showFields() {
    let selectedFieldType = this.consultantFormTypeSelectTarget.value
    if (selectedFieldType === window.selectEnum) {
      $(this.selectFieldsTarget).show();
    }
    else if (selectedFieldType === window.ynnaEnum) {
      $(this.ynnaFieldsTarget).show();
      this.showYnnaFields();
    }
    else if (selectedFieldType === window.textFieldEnum) {
      $(this.textBoxFieldsTarget).show();
    }
  }

  showYnnaFields() {
    let selectedHpa = this.hpaSelectTarget.value;
    if (selectedHpa === "optional" || selectedHpa === "forced") {
      $(this.hpaDocumentTarget).show();
    }
    else {
      $(this.hpaDocumentTarget).hide();
    }

    let selectedSwp = this.swpSelectTarget.value;
    if (selectedSwp === "optional" || selectedSwp === "forced") {
      $(this.swpDocumentTarget).show();
    }
    else {
      $(this.swpDocumentTarget).hide();
    }

    let selectedRa = this.raSelectTarget.value;
    if (selectedRa === "optional" || selectedRa === "forced") {
      $(this.raDocumentTarget).show();
    }
    else {
      $(this.raDocumentTarget).hide();
    }

    let selectedTraining = this.trainingSelectTarget.value;
    if (selectedTraining === "optional" || selectedTraining === "forced") {
      $(this.trainingDocumentTarget).show();
    }
    else {
      $(this.trainingDocumentTarget).hide();
    }
  }

  fieldTypeSelectValueChanged() {
    this.hideAllFields();
    this.showFields();
  }

  hpaSelectValueChanged() {
    this.showYnnaFields();
  }

  swpSelectValueChanged() {
    this.showYnnaFields();
  }

  raSelectValueChanged() {
    this.showYnnaFields();
  }

  trainingSelectValueChanged() {
    this.showYnnaFields();
  }
}
