import dataTable from 'datatables.net-bs5';
window.dataTable = dataTable(window, $);
import JSZip from 'jszip'
window.JSZip = JSZip
import 'pdfmake'
import buttons from 'datatables.net-buttons-bs5';
buttons(window.DataTable, $);
import columnVisibility from 'datatables.net-buttons/js/buttons.colVis.js';
columnVisibility(window.DataTable, $);
import buttonsHtml5 from 'datatables.net-buttons/js/buttons.html5.js';
buttonsHtml5(window.DataTable, $);
import buttonsPrint from 'datatables.net-buttons/js/buttons.print.js';
buttonsPrint(window.DataTable, $);
