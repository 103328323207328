import bootstrapPlugin from "@fullcalendar/bootstrap";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Tooltip } from "bootstrap";
import { DataTable } from "datatables.net-dt";
import Buttons from "datatables.net-buttons-dt";
import $ from "jquery";
import YearCalendar from "js-year-calendar";
//import "js-year-calendar/dist/js-year-calendar.css";

document.addEventListener("turbo:load", function () {
  var blockedDaysCalendarElement = document.getElementById("blockedDaysCalendar");

  if (blockedDaysCalendarElement) {
    var blockedDaysCalendar = new Calendar(blockedDaysCalendarElement, {
      plugins: [dayGridPlugin, interactionPlugin, bootstrapPlugin],
      schedulerLicenseKey: "0616196517-fcs-1661861808",
      initialView: "dayGridMonth",
      themeSystem: "bootstrap5",
      locale: "en-gb",
      timeZone: "UTC",
      events: window.location.pathname + ".json",
      headerToolbar: {
        left: "prev today",
        center: "title",
        right: "next"
      },
      eventClassNames: function() {
        return ['blocked-day'];
      },
      dateClick: function (info) {
        let currentBlockedDay = null;
        blockedDaysCalendar.getEvents().every(event => {
          if (event.startStr === info.dateStr) {
            currentBlockedDay = event;
            return false;
          }
          return true;
        });
        if (currentBlockedDay === null) {
          $.ajax({
            type: "POST",
            dataType: "JSON",
            url: window.createUrl,
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              blocked_day: {
                start_time: info.date.toISOString(),
              }
            },
            success: function (response) {
              blockedDaysCalendar.addEvent(response);
            },
            error: function () {
              alert("Unable to block day");
            }
          });
        } else {
          $.ajax({
            type: "DELETE",
            dataType: "JSON",
            url: currentBlockedDay.extendedProps.destroyUrl,
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
            },
            success: function (response) {
              currentBlockedDay.remove()
            },
            error: function (response) {
              alert("Unable to remove blocked day");
            }
          });
        }
      },
    });

    blockedDaysCalendar.render();
  }

  if ($(".year-calendar").length > 0) {
    $(".year-calendar-loading-overlay").show();
    window.employeeLeavesCalendar.calendar = new YearCalendar(".year-calendar", {
      language: "en",
      style: "custom",
      alwaysHalfDay: true,
      displayHeader: false,
      enableRangeSelection: true,
      weekStart: 1,
      startDate: window.employeeLeavesCalendar.startDate,
      loadingTemplate: '<div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      customDayRenderer: function (element, currentDate) {
        if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
          element.className += " weekend";
        }
        let today = new Date();
        if (today.getFullYear() === currentDate.getFullYear() && today.getMonth() === currentDate.getMonth() && today.getDate() === currentDate.getDate()) {
          element.className += " today";
        }
      },
      customDataSourceRenderer: function (element, date, events) {
        element.className += " date_" + date.toDateString().replace(/\s/g, "");
        if (events.length > 0) {
          element.className += " has-events ";
          element.innerHTML = '<span class="date">' + date.getDate() + '</span>';
          for (let i = 0; i < events.length; i++) {
            var event = events[i];
            let class_names = event.class_names;
            if (event.startDate.toDateString() != date.toDateString()) {
              class_names = class_names.replace("starts-at-noon", " ");
            }
            if (event.endDate.toDateString() != date.toDateString()) {
              class_names = class_names.replace("ends-at-noon", " ");
            }
            element.innerHTML = element.innerHTML + '<div class="event ' + class_names + '">' + '<i class="fa ' + event.icon + '">' + '</i></div>';
          }
        }
      },
      // tooltip
      mouseOnDay: function (e) {
        if (e.events.length > 0) {
          var content = [];
          for (var i in e.events) {
            var status = e.events[i].status && e.events[i].type != "UnauthorisedLeave" ? " - " + e.events[i].status : "";
            content.push(e.events[i].name + status);
          }
          var tooltip = new Tooltip(e.element, {
            container: "body",
            html: true,
            title: '<div class="tooltip-title-small">' + content.join(', ') + '</div>',
            placement: "bottom"
          });
          tooltip.show();
        }
      },
      mouseOutDay: function (e) {
        if (e.events && e.events.length > 0) {
          var tooltip = Tooltip.getInstance(e.element);
          if (tooltip) {
            tooltip.dispose();
          }
        }
      },
      dataSource: async function () {
        let json = await (await fetch(window.employeeLeavesCalendar.jsonEvents)).json();
        let events = json.map((event) => {
          return {
            id: event.id,
            name: event.title,
            startDate: new Date(event.start),
            endDate: new Date(event.end),
            color: event.backgroundColor,
            class_names: event.class_names,
            icon: event.icon,
            editUrl: event.editUrl,
            status: event.status,
            type: event.type,
          };
        });
        let public_holidays = window.employeeLeavesCalendar.publicHolidays.map((event) => {
          return {
            name: event.title,
            startDate: new Date(event.start),
            endDate: new Date(event.start),
            color: "lightgray",
            class_names: "public-holiday",
            icon: event.icon,
          };
        });
        let blocked_days_json = await (await fetch(window.employeeLeavesCalendar.blockedDays)).json();
        let blocked_days = blocked_days_json.map((event) => {
          return {
            name: event.title,
            startDate: new Date(event.start),
            endDate: new Date(event.start),
            color: event.backgroundColor,
            class_names: event.class_names,
            icon: event.icon,
          };
        });
        return events.concat(public_holidays).concat(blocked_days);
      },
      selectRange: function (selectInfo) {
        var hr_manager = window.employeeLeavesCalendar.hr_manager;
        var singleDaySelection = selectInfo.startDate == selectInfo.endDate;
        if (selectInfo.events.filter((event) => event.class_names.includes("public-holiday")).length > 0) {
          alert("You can't create a leave on a public holiday");
        } else if (selectInfo.events.filter((event) => event.class_names.includes("blocked-day")).length > 0 && !hr_manager) {
          alert("You can't create a leave on a blocked day");
        } else if (singleDaySelection && selectInfo.events.length > 1 && selectInfo.startDate == selectInfo.endDate) {
          $(".date_" + selectInfo.startDate.toDateString().replace(/\s/g, "")).popover({
            placement: "top",
            html: true,
            trigger: "focus",
            title: "Multiple leaves on same day",
            content: selectInfo.events.map((event) => {
              if (event.editUrl) {
                return '<div class="d-flex justify-content-between align-items-center multi-event-row"><div class="type-date-label mr-2">' + event.name + '</div><a href="' + event.editUrl + '" class="remote-link"><i class="picons-thin-icon-thin-0160_arrow_next_right"></i></a></div>';
              } else {
                return "<span>" + event.name + "</span>";
              }
            }).join(""),
          }).popover("show");
        } else if (singleDaySelection && selectInfo.events.length >= 1 && selectInfo.events[0].editUrl) {
          $.ajax({
            type: "GET",
            url: selectInfo.events[0].editUrl
          });
        }
        else {
          let membership_id = window.employeeLeavesCalendar.membershipId;

          let start_date = new Date(selectInfo.startDate);
          let end_date = new Date(selectInfo.endDate);

          start_date.setHours(0, 0, 0);
          end_date.setHours(23, 59, 59);

          $.ajax({
            type: "GET",
            url: window.employeeLeavesCalendar.newLeaveUrl,
            data: {
              start_time: start_date.toISOString(),
              end_time: end_date.toISOString(),
              membership_id: membership_id,
              dateAdjustment: "none",
            }
          });
        }
      }
    });

    document.querySelector('.year-calendar').addEventListener('renderEnd', function(e) {
      // Hide the loading overlay when the calendar is fully rendered
      setTimeout(function() {
        $(".year-calendar-loading-overlay").hide();
      }, 800)
    });


    $(document).on("click", ".remote-link", function (event) {
      $(".popover").popover("hide");
      event.preventDefault();
      $.ajax({
        type: "GET",
        url: $(this).attr("href")
      });
    });
  }

  if ($("#leave-list").length > 0) {

    document.querySelector('.calendar-tab').addEventListener('click', function(e) {
      // Show the loading overlay
      $(".year-calendar-loading-overlay").show();

      // Add a delay before hiding the loading overlay
      setTimeout(function() {
        $(".year-calendar-loading-overlay").hide();
      }, 800);
    });

    document.querySelector('.list-tab').addEventListener('click', function(e) {
      // Hide the loading overlay
      $(".year-calendar").hide();
    });
  }


  $("#new-custom-field-btn").on("click", function () {
    var activeTab = $(".nav-tabs .active").data("report");
    var link = $(this).attr("href");
    $(this).attr("href", link + "?previous_tab=" + activeTab);
  });

  if ($(".employee-leave-dt").length > 0) {
    function format(d) {
      // `d` is the original data object for the row
      return (
        '<table cellpadding="0" cellspacing="0" border="0" class="table table-sm table-child mb-0" width="100%">' +
        '<tr>' +
        '<td style="width: 150px" class="ps-3">Accepted Reason:</td>' +
        '<td>' +
        d.leave_reason +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="width: 150px" class="ps-3">Decline Reason:</td>' +
        '<td>' +
        // not empty
        (d.decline_reason ? d.decline_reason : 'N/A') +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="width: 150px" class="ps-3">Notes:</td>' +
        '<td>' +
        d.notes +
        '</td>' +
        '</tr>' +
        '</table>'
      );
    }

    var employeeLeaveDt = $(".employee-leave-dt").DataTable({
      dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center title-container"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
      stateSave: false,
      bDestroy: true,
      data: window.employeeLeavesDataTable.employee_leaves,
      pageLength: 25,
      order: [],
      language: {
        search: "",
        searchPlaceholder: "Search...",
        lengthMenu: "Show _MENU_",
        zeroRecords: "No leave requests found",
        info: "Showing _START_ to _END_ of _TOTAL_ leave requests",
        infoEmpty: "No leave requests found",
        infoFiltered: "(filtered from _MAX_ total leave requests)",
        paginate: {
          previous: "<i class='bi bi-chevron-left'></i>",
          next: "<i class='bi bi-chevron-right'></i>"
        }
      },
      columns: [
        {
          className: "dt-row-control",
          orderable: false,
          data: null,
          defaultContent: '<i class="bi bi-plus-circle"></i>',
          width: "15px"
        },
        {
          data: "type",
          defaultContent: ""
        },
        {
          data: {
            _: "start_date.display",
            sort: "start_date.timestamp"
          },
          defaultContent: ""
        },
        {
          data: {
            _: "end_date.display",
            sort: "end_date.timestamp"
          },
          defaultContent: ""
        },
        {
          data: "status",
          defaultContent: "",
          render: function (data, type, row) {
            return `<small class="status-badge status-${data} text-capitalize">${data}</small>`;
          }
        },
        {
          data: {
            _: "created_date.display",
            sort: "created_date.timestamp"
          },
          defaultContent: ""
        },
      ],
      drawCallback: function () {
        $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
        $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
      },
      initComplete: function () {
        $(".table thead th:last-child").removeClass("d-flex justify-content-end");
        $(".dataTables_info").addClass("pt-0");
      }
    });

    // Add event listener for opening and closing details
    $("#leave-list-dt tbody").on("click", "td.dt-row-control", function () {
      var tr = $(this).closest("tr");
      var row = employeeLeaveDt.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
        // add icon
        $(this).html('<i class="bi bi-plus-circle"></i>');
      } else {
        // Open this row
        row.child(format(row.data())).show();
        tr.addClass("shown");
        // add icon
        $(this).html('<i class="bi bi-dash-circle"></i>');
        // if shown find next row and add class
        tr.next().addClass("child-row");
      }
    });

    document.addEventListener("turbo:before-cache", function () {
      if (employeeLeaveDt !== null) {
        employeeLeaveDt.destroy();
        employeeLeaveDt = null;
      }
    });
  }

  const teamLeavesCalendarElement = document.getElementById("teamLeavesCalendar");
  if (teamLeavesCalendarElement) {
    window.teamLeavesCalendar.calendar = new Calendar(teamLeavesCalendarElement, {
      plugins: [momentTimezonePlugin, resourceTimelinePlugin, listPlugin, bootstrapPlugin, interactionPlugin],
      schedulerLicenseKey: "0616196517-fcs-1661861808",
      initialView: "resourceTimelineMonth",
      themeSystem: "bootstrap5",
      height: "auto",
      locale: "en-gb",
      selectable: true,
      timeZone: "Europe/London",
      resourceAreaHeaderContent: "Members",
      validRange: {
        start: window.teamLeavesCalendar.yearStart,
        end: window.teamLeavesCalendar.yearEnd,
      },
      resourceAreaWidth: "15%",
      slotMinWidth: 15,
      headerToolbar: {
        left: "prev",
        center: "title",
        right: "next"
      },
      slotDuration: {
        "hours": 12
      },
      slotLabelInterval: {
        "hours": 24
      },
      slotLabelFormat: [
        {
          weekday: "narrow",
          day: "numeric"
        }],
      nowIndicator: true,
      resourceOrder: "title",
      resources: window.teamLeavesCalendar.teamMembers,
      eventSources: [window.location.pathname + ".json", window.teamLeavesCalendar.publicHolidays, window.teamLeavesCalendar.blockedDaysUrl],
      resourceLabelContent: function (arg) {
        return { html: `<a href="${window.teamLeavesCalendar.membershipPath + arg.resource.id}">${arg.resource.title}</a>` };
      },
      eventContent: function (entry) {
        let event = entry.event;
        let icon = event.extendedProps.icon;
        return { html: `<i class="fa ${icon}"></i>` };
      },
      eventDidMount: function (entry) {
        let element = entry.el;
        let event = entry.event;
        let status = event.extendedProps.status ? " - " + event.extendedProps.status : "";
        let title = event.title + status;
        let class_names = event.extendedProps.class_names;

        class_names = class_names.split(" ").filter(n => n);
        for (let index = 0; index < class_names.length; index++) {
          element.classList.add(class_names[index]);
        }
        element.classList.add("text-center");

        var tooltip = new Tooltip(element, {
          title: title,
          placement: "right",
          container: "body",
          trigger: "hover"
        });
        // tooltip.show();
      },
      eventWillUnmount: function (entry) {
        let element = entry.el;
        var tooltip = Tooltip.getInstance(element);
        if (tooltip) tooltip.dispose();
      },
      eventClick: function (eventClickInfo) {
        $.ajax({
          type: "GET",
          url: eventClickInfo.event.extendedProps.editUrl
        });
      },
      select: function (selectInfo) {
        const canAdd = selectInfo.resource.getEvents().every(event => {
          let end = event.end;
          if (end === null) {
            end = event.start;
            end.setHours(23, 59, 59, 999);
          }
          if ((event.start < selectInfo.end) && (selectInfo.start < end) && (event.extendedProps.class_names.includes("blocked-day"))) return true; // allow blocked days to be overlapped in case manager needs to add a special case leave
          if ((event.start < selectInfo.end) && (selectInfo.start < end)) return false; // if events overlap
          return true;
        });
        if (canAdd) {
          let membership_id = selectInfo.resource.id;
          $.ajax({
            type: "GET",
            url: `/hr/memberships/${membership_id}/employee_leaves/new`,
            data: {
              start_time: selectInfo.start.toISOString(),
              end_time: selectInfo.end.toISOString(),
              membership_id: membership_id
            }
          });
        } else {
          window.teamLeavesCalendar.calendar.unselect();
          alert("Date range cannot overlap with existing leaves");
        }
      },
    });

    window.teamLeavesCalendar.calendar.render()
  }

  if ($(".acceptedLeaveTable").length) {
    // add class override to accepted leave table elements only

    var leaveTable = $(".acceptedLeaveTable").DataTable({
      bDestroy: true,
      data: window.teamLeavesDataTable.acceptedEmployeeLeaves,
      dom: '<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
      order: [2, 'asc'],
      pageLength: 25,
      pagingType: "simple",
      columns: [
        {
          data: "name",
          defaultContent: ""
        },
        {
          data: "type",
          defaultContent: ""
        },
        {
          data: {
            _: "start_date.display",
            sort: "start_date.timestamp"
          },
          defaultContent: ""
        },
        {
          data: {
            _: "end_date.display",
            sort: "end_date.timestamp"
          },
          defaultContent: ""
        },
        {
          data: "days_count",
          defaultContent: ""
        },
      ],
      language: {
        emptyTable: "No leave requests found",
        info: "_START_ to _END_ <span class='text-muted'>of _TOTAL_</span>",
        infoEmpty: "0 to 0 <span class='text-muted'>of 0</span>",
        infoFiltered: "(filtered from _MAX_ total leave requests)",
        lengthMenu: "Show requests _MENU_ ",
        search: "Search:",
        zeroRecords: "No matching leave requests found",
        paginate: {
          previous: "<i class='bi bi-chevron-left'></i>",
          next: "<i class='bi bi-chevron-right'></i>"
        }
      },
      drawCallback: function () {
        // remove .pagination class
        $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
        $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");

      }
    });
    // add class to dataTables_info
    $(".dataTables_info").addClass("pt-0");

    document.addEventListener("turbo:before-cache", function () {
      if (leaveTable !== null) {
        leaveTable.destroy();
        leaveTable = null;
      }
    });
  }

  if ($(".holidayAllowanceTable").length) {
    var staffAllowanceTable = $(".holidayAllowanceTable").DataTable({
      bDestroy: true,
      data: window.teamLeavesDataTable.holidayAllowance,
      dom: '<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
      columnDefs: [{
        defaultContent: "",
        targets: "_all"
      }],
      language: {
        emptyTable: "No staff members found",
        info: "_START_ to _END_ <span class='text-muted'>of _TOTAL_</span>",
        infoEmpty: "Showing 0 to 0 <span class='text-muted'>of 0</span>",
        infoFiltered: "(filtered from _MAX_ total staff members)",
        lengthMenu: "Show staff _MENU_",
        search: "Search:",
        zeroRecords: "No matching staff members found",
        paginate: {
          previous: "<i class='bi bi-chevron-left'></i>",
          next: "<i class='bi bi-chevron-right'></i>"
        }
      },
      drawCallback: function () {
        // remove .pagination class
        $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
        $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");

      }
    });
    $(".dataTables_info").addClass("pt-0");
    // TODO sort out the buttons
    var staffAllowanceButtons = new Buttons(staffAllowanceTable, {
      buttons: [
        {
          extend: "csv",
          text: "Download CSV",
          className: "dropdown-item"
        },
        {
          extend: "pdf",
          text: "Download PDF",
          className: "dropdown-item"
        }
      ]
    })
    staffAllowanceButtons.buttons().container().appendTo($("#staffAllowanceExport"));

    document.addEventListener("turbo:before-cache", function () {
      if (staffAllowanceTable !== null) {
        staffAllowanceTable.destroy();
        staffAllowanceTable = null;
      }
    });
  }

  const fileInput = document.querySelector('input[type="file"]');
  const uploadedFilesContainer = document.querySelector('.uploaded-files');

  if (fileInput && uploadedFilesContainer) {
    fileInput.addEventListener('input', function() {
      const files = fileInput.files;

      if (files.length > 1) {
        const selectedFileNames = Array.from(files).map(file => file.name);

        // Update the uploadedFilesContainer with the selected file names
        let fileNamesString = selectedFileNames.map((name, index) => `<span>${name}${index < selectedFileNames.length - 1 ? ', ' : ''}</span>`).join(' ');
        uploadedFilesContainer.innerHTML = fileNamesString;
      } else {
        // Clear the uploadedFilesContainer if less than 2 files are selected
        uploadedFilesContainer.innerHTML = '';
      }
    });
  }

})
