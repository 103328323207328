import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropzone"
export default class extends Controller {
  connect() {
    this.enableDropzone($(this.element).parent());
  }

  // enableDropzone($scope) {
  //   if ($scope.find(".misdropzone").length > 0) {
  //     console.log("misdropzone")
  //     // Dropzone.options.misdropzone = {
  //     // };
  //     new Dropzone(".misdropzone", {
  //       dictDefaultMessage: 'Drop files here or click to upload',
  //       maxFilesize: 10
  //     });
  //   }


  //   if ($scope.find(".filingcabmisdropzone").length > 0) {
  //     // Dropzone.options.misdropzone = {
  //     // };
  //     var dz = new Dropzone(".filingcabmisdropzone", {
  //       dictDefaultMessage: 'Drop files here or click to upload',
  //       maxFilesize: 10
  //     });
  //     dz.on("success", function (file) {
  //       $.get(window.location.pathname + '.js');
  //     });
  //   }

  //   if ($scope.find(".reloadmisdropzone").length > 0) {
  //     // Dropzone.options.misdropzone = {
  //     // };
  //     var dz = new Dropzone(".reloadmisdropzone", {
  //       dictDefaultMessage: 'Drop documents here or click to upload',
  //       maxFilesize: 10
  //     });
  //     dz.on("success", function (file) {
  //       $.get(window.location.pathname + '.js');
  //     });
  //   }

  //   if ($scope.find(".reloadmisdropzoneimageonly").length > 0) {
  //     // Dropzone.options.misdropzone = {
  //     // };
  //     var dzimage = new Dropzone(".reloadmisdropzoneimageonly", {
  //       dictDefaultMessage: 'Select photos',
  //       maxFilesize: 10,
  //       acceptedFiles: ".jpeg,.jpg,.png,.gif",
  //       capture: "camera"
  //     });
  //     dzimage.on("success", function (file) {
  //       $.get(window.location.pathname + '.js');
  //     });
  //   }
  // }
  enableDropzone($scope) {
    $scope.find(".misdropzone, .filingcabmisdropzone, .reloadmisdropzone, .reloadmisdropzoneimageonly").each(function() {
      if (!this.dropzone) { // Check if Dropzone is already attached
        let options = {
          // add icon to dictDefaultMessage
          dictDefaultMessage: '<i class="bi bi-cloud-arrow-up"></i> Drop files here or click to upload',
          maxFilesize: 50,
          addRemoveLinks: true,
          dictRemoveFile: "<div><i class='bi bi-trash text-danger'></i></div>",
          dictCancelUpload: "<div><i class='bi bi-x-lg text-danger'></i></div>",
        };

        if ($(this).hasClass('reloadmisdropzoneimageonly')) {
          options.dictDefaultMessage = 'Select photos';
          options.acceptedFiles = ".jpeg,.jpg,.png,.gif";
          options.capture = "camera";
        } else if ($(this).hasClass('reloadmisdropzone')) {
          options.dictDefaultMessage = '<i class="bi bi-cloud-arrow-up"></i> Drop files here or click to upload';
        }

        let dz = new Dropzone(this, options);

        dz.on("addedfile", function(file) {
          // Disable the remove link by default
          file.previewElement.querySelector(".dz-remove").style.display = "none";
        });

        dz.on("error", function(file, errorMessage) {
          // If there's an error or the file is too big, enable the remove link
          if (errorMessage || file.size > dz.options.maxFilesize * 1024 * 1024) {
            file.previewElement.querySelector(".dz-remove").style.display = "block";
          }
        });

        dz.on("success", function (file) {
          $.get(window.location.pathname + '.js');
        });

        dz.options.dictDefaultMessage = '<i class="bi bi-cloud-arrow-up"></i> Drop files here or click to upload';
      }
    });
  }
}
