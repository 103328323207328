import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="licences"
export default class extends Controller {
  static targets = ["actionUrl"]

  connect() {
    // Rails.ajax({ url: this.actionUrlTarget.value, type: 'get' })
  }

  index(event) {
    console.log(event.target.dataset)
    Turbo.visit(`/memberships/${event.target.dataset.membershipid}/licences`)
    event.preventDefault()
  }

  show(event) {
    Turbo.visit(`/licences/${event.target.dataset.id}`)
    event.preventDefault()
  }

  edit(event) {
    console.log(event.target.dataset)
    console.log(event.target)
    Turbo.visit(`/licences/${event.target.dataset.id}/edit`)
    event.preventDefault()
  }

  // destroy(event) {
  //   if (confirm("Are you sure?")) {
  //     Rails.ajax({ url: `/licences/${event.target.dataset.id}`, type: 'delete' })
  //   }
  //   event.preventDefault()
  // }
}
