import { Controller } from "@hotwired/stimulus"
// import bootstrap daterangepicker
import { DateRangePicker } from 'daterangepicker'

export default class extends Controller {
  connect() {
    this.enableDateFields();
  }
  enableDateFields() {
    this.element.querySelectorAll('input.timepicker').forEach((input) => {
      // input fields with the class 'timepicker' should be empty initially
      // so that the timepicker doesn't automatically set the current time
      // when the page loads.
      if (!input.value) {
        input.value = '';
      }
      $(input).daterangepicker({
        timePicker: true,
        singleDatePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 5,
        timePickerSeconds: false,
        showDropdowns: true,
        autoApply: false,
        autoUpdateInput: false, // Prevent automatic update of the input field
        showCancelButton: true,
        locale: {
          format: 'HH:mm',
          cancelLabel: 'Clear',
        }
      }).on('show.daterangepicker', function (ev, picker) {
        picker.container.addClass('timepicker');
        picker.container.find(".calendar-table").hide();
        picker.container.find(".drp-selected").hide();
      }).on('cancel.daterangepicker', function(ev, picker) {
        $(this).val(''); // Clear the input field when the cancel button is clicked
      }).on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('HH:mm')); // Update the input field when a date range is applied
      });
    });
  }
}
