import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(function() {
      if (!$.fn.DataTable.isDataTable('.dt-documents')) {
        let filecabinet_dt = $('.dt-documents').DataTable({
          dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center title-container"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
          pagingType: "simple",
          stateSave: true,
          bLengthChange: true,
          bPaginate: true,
          bFilter: true,
          bDestroy: true,
          columnDefs: [
              { orderable: false, targets: -1 },
              { className: "justify-content-end d-flex", targets: -1 }
          ],
          stateSaveParams: function (settings, data) {
              data.length = 25;
          },
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          language: {
            emptyTable: "No files uploaded to folder",
            paginate: {
              previous: "<i class='bi bi-chevron-left'></i>",
              next: "<i class='bi bi-chevron-right'></i>"
            }
          },
          drawCallback: function () {
            $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
            $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
          },
          initComplete: function () {
            $(".table thead th:last-child").removeClass("d-flex justify-content-end");
            $(".dataTables_info").addClass("pt-0");
          }
      });
        window.filecabinet_dt = filecabinet_dt;
        document.addEventListener('turbo:before-cache', function () {
          filecabinet_dt.destroy();
        });
      }
    });
  }
}
