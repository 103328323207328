import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { DataTable } from "datatables.net-dt";
import $ from "jquery";

document.addEventListener("turbo:load", function () {
  function clearDate(event) {
    event.preventDefault();
    const dateField = $(event.target).closest(".date-input").find("input");
    dateField.val(null);
  }

  // where name is scheduled_report[every]
  $('#scheduled_report_every,select[name="scheduled_report[every]"]').on('change', function () {
    console.log("CHANGED! ", $(this).val());
        if ($(this).val() == 'week') {
            $('.days-of-week-options').show();
        } else {
            $('.days-of-week-options').hide();
        }
        $('.every-label').text($(this).val() + 's')
    });

    $(document).find('input[data-behaviour~=repeatchbx]').unbind('click');
    $(document).find('input[data-behaviour~=repeatchbx]').on('click', function () {
        if ($('input[data-behaviour~=repeatchbx]').is(':checked')) {
            $('.repeat-options').show();
            $('.scheduled_at_date_row label').text('Starting On');
        } else {
            $('.repeat-options').hide();
            $('.scheduled_at_date_row label').text('Scheduled On');
        }
    });

  $("input.single-daterange").each(function () {
    $(this).daterangepicker({
      showDropdowns: true,
      minYear: $(this).hasClass("future-only") ? new Date().getFullYear() : new Date().getFullYear() - 100,
      maxYear: new Date().getFullYear() + 5,
      singleDatePicker: true,
      autoApply: true,
      autoUpdateInput: false,
      locale: {
        cancelLabel: "Clear",
        firstDay: 1,
        format: "DD/MM/YYYY",
      },
    });
  });

  $("input.single-daterange").on("apply.daterangepicker", function(ev, picker) {
    $(this).val(picker.startDate.format("DD/MM/YYYY"));
  });

  $("input.single-daterange").on("cancel.daterangepicker", function(ev, picker) {
    $(this).val("");
  });

  $(".date-input button.clear").on("click", clearDate);

  if (window.calendarEventsUrl) {
    var calendarEl = document.getElementById("calendar");
    let calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      editable: true,
      initialView: "dayGridMonth",
      locale: "en-gb",
      titleFormat: {
        month: "2-digit",
        year: "numeric",
        day: "numeric",
      },
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth listWeek"
      },
      buttonText: {
        today: "Today",
        month: "Month View",
        list: "List View"
      },
      eventSources: [
        {
          url: window.calendarEventsUrl,
          // color: "yellow",
          textColor: "black"
        }
      ],
      eventTimeFormat: {
        hour: "numeric",
        minute: "numeric",
        meridiem: "short",
        omitZeroMinute: true
      },
      eventDidMount: function (info) {
        // $(info.el).tooltip({
        //   title: info.event.title,
        // });
      },
      dateClick: function (info) {
        Turbo.visit(window.newEventsUrl + "?scheduled_at=" + info.dateStr);
      },
      eventClick: function (info) {
        // info.jsEvent.preventDefault();
        // $.get(info.event.url + ".js");
      },
      eventDrop: function (info) {
        $.ajax({
          url: info.event.url + ".js",
          type: "PUT",
          data: {
            scheduled_report: {
              scheduled_at: info.event.start
            }
          },
        });
      },
      noEventsContent: "No checklists scheduled",
    });

    calendar.render();
    window.calendar = calendar;
  }
});
