import { Controller } from "@hotwired/stimulus"
import ClipboardJS from 'clipboard'
import { Tooltip } from 'bootstrap'

// Connects to data-controller="clipboard"
export default class extends Controller {
  connect() {
    this.initClipboard()
  }
  initClipboard() {
    var clipboard = new ClipboardJS('.copy-btn', {
      text: function(trigger) {
        return trigger.getAttribute('data-clipboard-text');
      }
    });

    clipboard.on('success', function(e) {
      var tooltip = new Tooltip(e.trigger, {
        title: 'Copied!',
        placement: 'top',
        trigger: 'manual'
      });
      tooltip.show();
      setTimeout(function() {
        tooltip.hide();
      }, 1000);
    });
  }
}
