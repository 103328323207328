import $ from 'jquery';
import * as bootstrap from 'bootstrap';
import 'datatables.net-bs5';

const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new bootstrap.Dropdown(dropdownToggleEl))

window.editorjs = {};
import EditorJS from "@editorjs/editorjs";
window.editorjs.EditorJS = EditorJS;
import Header from "@editorjs/header";
window.editorjs.Header = Header;
import List from "@editorjs/list";
window.editorjs.List = List;
import Table from "editorjs-table";
window.editorjs.Table = Table;
import Marker from "@editorjs/marker";
window.editorjs.Marker = Marker;
import Delimiter from "@editorjs/delimiter";
window.editorjs.Delimiter = Delimiter;
import edjsHTML from "editorjs-html";
window.editorjs.edjsHTML = edjsHTML;
import Undo from "editorjs-undo";
window.editorjs.Undo = Undo;
import ImageTool from "@editorjs/image";
window.editorjs.ImageTool = ImageTool;
import Paragraph from "editorjs-paragraph-with-alignment";
window.editorjs.Paragraph = Paragraph;
import Underline from "@editorjs/underline";
window.editorjs.Underline = Underline;
import Layout from "editorjs-layout";
window.editorjs.Layout = Layout;
import RawTool from "@editorjs/raw";
window.editorjs.RawTool = RawTool;
import NestedList from "@editorjs/nested-list";
window.editorjs.NestedList = NestedList;
const ColorPlugin = require('editorjs-text-color-plugin');
window.editorjs.ColorPlugin = ColorPlugin;

window.tableParser = function tableParser(block) {
  var _html = "<table>";
  block.data.content.forEach(row => {
    _html = _html + "<tr>";
    row.forEach(cell => {
      _html = _html + "<td>" + cell + "</td>";
    });
    _html = _html + "</tr>";
  });
  _html = _html + "</table>";
  return _html;
}

window.nestedListParser = function nestedListParser(block) {
  return "<br/>";
}

class NarrowImageTool extends ImageTool {

  static get toolbox() {
    return {
      icon: "<i class='far fa-columns'/>",
      title: "Image in Columns",
    };
  }

  render(toolData) {
    var w = $(this.ui.nodes.wrapper);
    w.addClass("width_half");
    return this.ui.render(this.data);
  }
}
window.editorjs.NarrowImageTool = NarrowImageTool;

class ImageTool2 extends ImageTool {
  render(toolData) {
    if (window.imageInThirds && window.imageInThirds.includes(this.data.file.url)) {
      this.setTune("widthThird", true);
    }
    if (window.imageInHalves && window.imageInHalves.includes(this.data.file.url)) {
      this.setTune("widthHalf", true);
    }
    return this.ui.render(this.data);
  }
}
window.editorjs.ImageTool2 = ImageTool2;

class SimpleImage {
  constructor() {
    this.data = data;
    this.wrapper = undefined;
  }

  renderSettings() {
    const settings = [
      {
        name: "withBorder",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.8 10.592v2.043h2.35v2.138H15.8v2.232h-2.25v-2.232h-2.4v-2.138h2.4v-2.28h2.25v.237h1.15-1.15zM1.9 8.455v-3.42c0-1.154.985-2.09 2.2-2.09h4.2v2.137H4.15v3.373H1.9zm0 2.137h2.25v3.325H8.3v2.138H4.1c-1.215 0-2.2-.936-2.2-2.09v-3.373zm15.05-2.137H14.7V5.082h-4.15V2.945h4.2c1.215 0 2.2.936 2.2 2.09v3.42z"/></svg>`
      },
      {
        name: "stretched",
        icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`
      },
      {
        name: "withBackground",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.043 8.265l3.183-3.183h-2.924L4.75 10.636v2.923l4.15-4.15v2.351l-2.158 2.159H8.9v2.137H4.7c-1.215 0-2.2-.936-2.2-2.09v-8.93c0-1.154.985-2.09 2.2-2.09h10.663l.033-.033.034.034c1.178.04 2.12.96 2.12 2.089v3.23H15.3V5.359l-2.906 2.906h-2.35zM7.951 5.082H4.75v3.201l3.201-3.2zm5.099 7.078v3.04h4.15v-3.04h-4.15zm-1.1-2.137h6.35c.635 0 1.15.489 1.15 1.092v5.13c0 .603-.515 1.092-1.15 1.092h-6.35c-.635 0-1.15-.489-1.15-1.092v-5.13c0-.603.515-1.092 1.15-1.092z"/></svg>`
      }
    ];

    const wrapper = document.createElement("div");
    settings.forEach(tune => {
      let button = document.createElement("div");

      button.classList.add("cdx-settings-button");
      button.innerHTML = tune.icon;
      wrapper.appendChild(button);
    });

    return wrapper;
  }
}
window.editorjs.SimpleImage = SimpleImage;

$(document).on("turbo:load", function () {
  $(window).on('resize', function() {
    const sidebarHeaderHeight = $('.hs-sidebar-header').outerHeight();
    const sidebarHeight = $('.hs-sidebar').outerHeight();
    const sidebarContentHeight = sidebarHeight - sidebarHeaderHeight;
    $(':root').css('--sidebar-content-height', sidebarContentHeight + 'px');
  }).trigger('resize');

  if ($("#editorsection").length > 0) {
    const editor = new EditorJS({
      holder: "editorsection",
      readOnly: false,
      tools: {
        header: {
          class: Header,
          placeholder: "Enter a header",
          // inlineToolbar: ["link"],
          levels: [1, 2, 3],
          defaultLevel: 2
        },
        list: {
          class: List,
          inlineToolbar: true
        },
        table: {
          class: Table,
        },
        marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        delimiter: Delimiter,
        Color: {
          class: ColorPlugin,
          config: {
            colorCollections: ["#FFF", "000000", "#EC7878", "#9C27B0", "#673AB7", "#3F51B5", "#0070FF", "#03A9F4", "#00BCD4", "#4CAF50", "#8BC34A", "#CDDC39"],
            defaultColor: "#FF1300",
            type: "text",
            customPicker: true
          }
        },
      },
      data: {
        blocks: [
          {
            type: "header",
            id: "myid",
            data: {
              id: "myid",
              text: "Editor.js",
              level: 2
            }
          },
          {
            type: "paragraph",
            data: {
              text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text. Source code of the page contains the example of connection and configuration."
            }
          },
          {
            type: "header",
            data: {
              text: "Key features",
              level: 3
            }
          },
          {
            type: "list",
            data: {
              items: [
                "It is a block-styled editor",
                "It returns clean data output in JSON",
                "Designed to be extendable and pluggable with a simple API",
              ],
              style: "unordered"
            }
          },
          {
            type: "header",
            data: {
              text: "What does it mean «block-styled editor»",
              level: 3
            }
          },
          {
            type: "paragraph",
            data: {
              text: 'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class=\"cdx-marker\">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.'
            }
          },
          {
            type: "paragraph",
            data: {
              text: `There are dozens of <a href="https://github.com/editor-js">ready-to-use Blocks</a> and the <a href="https://editorjs.io/creating-a-block-tool">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games.`
            }
          },
          {
            type: "header",
            data: {
              text: "What does it mean clean data output",
              level: 3
            }
          },
          {
            type: "paragraph",
            data: {
              text: "Classic WYSIWYG-editors produce raw HTML-markup with both content data and content appearance. On the contrary, Editor.js outputs JSON object with data of each Block. You can see an example below"
            }
          },
          {
            type: "paragraph",
            data: {
              text: `Given data can be used as you want: render with HTML for <code class="inline-code">Web clients</code>, render natively for <code class="inline-code">mobile apps</code>, create markup for <code class="inline-code">Facebook Instant Articles</code> or <code class="inline-code">Google AMP</code>, generate an <code class="inline-code">audio version</code> and so on.`
            }
          },
          {
            type: "paragraph",
            data: {
              text: "Clean data is useful to sanitize, validate and process on the backend."
            }
          },
          {
            type: "delimiter",
            data: {}
          },
          {
            type: "paragraph",
            data: {
              text: "We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make its core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏"
            }
          },
        ]
      },
      onReady: function () {
        saveButton.click();
      },
      onChange: function () { }
    });

    const saveButton = document.getElementById("saveButton");
    const toggleReadOnlyButton = document.getElementById("toggleReadOnlyButton");
    const readOnlyIndicator = document.getElementById("readonly-state");

    saveButton.addEventListener("click", function () {
      editor.save()
        .then((savedData) => {
          // console.log(savedData);
          // const edjsParser = edjsHTML();
          // let html = edjsParser.parse(savedData);
          // console.log(html);
        })
        .catch((error) => {
          console.error("Saving error", error);
        });
    });

    /**
     * Toggle read-only example
     */
    toggleReadOnlyButton.addEventListener("click", async () => {
      const readOnlyState = await editor.readOnly.toggle();
      readOnlyIndicator.textContent = readOnlyState ? "On" : "Off";
    });
  }

  if ($(".editor-section").length > 0) {
    $.get(window.location.href + ".js", {});
  }

  var dt2 = $(".dt-simple").DataTable({
    dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-0"i><p>>>>>',
    stateSave: true,
    bPaginate: false,
    bLengthChange: false,
    bFilter: true,
    bInfo: true,
    bDestroy: true,
    bAutoWidth: false,
    columnDefs: [
      { orderable: false, targets: -1 },
      { className: "text-end", targets: -1 }
    ],
    initComplete: function () {
      // remove class from last th column
      $(".table thead th:last-child").removeClass("text-end");
    }
  });

  let dts = [];
  $(".safety-dt").each(function () {
    let table_element = $(this);
    let dt = table_element.DataTable({
      dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
      pagingType: "simple",
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
      language: {
        emptyTable: table_element.data("empty-table"),
        info: table_element.data("info"),
        infoEmpty: table_element.data("info-empty"),
        infoFiltered: table_element.data("info-filtered"),
        lengthMenu: 'Show _MENU_',
        zeroRecords: table_element.data("zero-records"),
        paginate: {
          previous: "<i class='bi bi-chevron-left'></i>",
          next: "<i class='bi bi-chevron-right'></i>"
        }
      },
      columnDefs: [
        { orderable: false, targets: -1 },
        { className: "text-end", targets: -1 }
      ],
      drawCallback: function () {
        $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
        $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
      },
      responsive: true,
      initComplete: function () {
        // remove class from last th column
        $(".table thead th:last-child").removeClass("text-end");
      }
    });
    dts.push(dt);
  });

  $(".dataTables_info").addClass("pt-0");

  document.addEventListener("turbo:before-visit", function () {
    dts.forEach(function (dt) {
      dt.destroy();
    });
    dts = [];
  });

  document.addEventListener("turbo:before-cache", function () {
    dts.forEach(function (dt) {
      dt.destroy();
    });
    dts = [];
  });



  document.addEventListener("turbo:before-cache", function () {
    if (dt2 !== null) {
      dt2.destroy();
      dt2 = null;
    }
  });

  // $(".todo-sidebar-section-sub-section-toggle").not(".disabled").on("click", function () {
  //   const link = $(this);
  //   const path = $(this).data("path");
  //   const sectionId = $(this).data("sectionid");
  //   var documentsContainer = $("#active-documents-" + sectionId);
  //   let section_container = $(".todo-sidebar-section-sub-section[data-sectionid=" + sectionId + "]");

  //   if (path && sectionId && documentsContainer && documentsContainer.children("li").length == 0) {
  //     section_container.find(".todo-sidebar-section-sub-section-content").slideToggle(100);
  //     $.ajax({
  //       url: path,
  //       dataType: "html",
  //       success: function (response) {
  //         documentsContainer.html(response);
  //       },
  //       error: function (xhr, status, error) {
  //         documentsContainer.html("Error loading documents");
  //       }
  //     });
  //   } else {
  //     // Sidebar Sub Sections foldable trigger
  //     $(this).closest(".todo-sidebar-section-sub-section").find(".todo-sidebar-section-sub-section-content").slideToggle(100);
  //   }

  //   $(this).find("i").toggleClass("bi-plus bi-dash");
  //   section_container.find(".section-icon i").toggleClass("bi-folder2 bi-folder2-open");
  // });

  // // open/highlight relevant bits of the side bar
  // const current_section_id = window.hs_section_id;
  // if (current_section_id) {
  //   let section_container = $(".todo-sidebar-section-sub-section[data-sectionid=" + current_section_id + "]");
  //   section_container.addClass("active");
  //   let toggler = section_container.find(".todo-sidebar-section-sub-section-toggle");
  //   section_container.find(".todo-sidebar-section-sub-section-toggle i").toggleClass("bi-plus bi-dash");
  //   section_container.find(".section-icon i").toggleClass("bi-folder2 bi-folder2-open");
  //   section_container.find(".todo-sidebar-section-sub-section-content").show();

  //   var path = toggler.data("path");
  //   var documentsContainer = $("#active-documents-" + current_section_id);

  //   if (path && documentsContainer) {
  //     $.ajax({
  //       url: path,
  //       dataType: "html",
  //       success: function (response) {
  //         documentsContainer.html(response);
  //       },
  //       error: function (xhr, status, error) {
  //         documentsContainer.html("Error loading documents");
  //       }
  //     });
  //   }
  //   let current_document_id = window.hs_document_id;
  //   if (current_document_id) {
  //     section_container.find(".document-item[data-documentid=" + current_document_id + "]").addClass("active");
  //   }
  // }

  $(".slide-panel-btn").on("click", function (e) {
    const target_panel = $($(this).data("target"));
    if (target_panel.hasClass("slide-panel-on")) {
      $(".slide-panel").removeClass("slide-panel-on");
      $(".slide-panel-btn").removeClass("active");
      var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-menu'))
      dropdownElementList.map(function (dropdownToggleEl) {
        var dropdown = bootstrap.Dropdown.getInstance(dropdownToggleEl)
        if (dropdown) {
          dropdown.hide()
        }
      })
    } else {
      $(".slide-panel").removeClass("slide-panel-on");
      $(".slide-panel-btn").removeClass("active");
      target_panel.addClass("slide-panel-on");
      $(this).addClass("active");
      var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-menu'))
      dropdownElementList.map(function (dropdownToggleEl) {
        var dropdown = bootstrap.Dropdown.getInstance(dropdownToggleEl)
        if (dropdown) {
          dropdown.hide()
        }
      })


    }
    e.stopPropagation()
  });
  $(".slide-panel-btn-close").on("click", function (e) {
    $(".slide-panel").removeClass("slide-panel-on");
    $(".slide-panel-btn").removeClass("active");
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-menu'))
      dropdownElementList.map(function (dropdownToggleEl) {
        var dropdown = bootstrap.Dropdown.getInstance(dropdownToggleEl)
        if (dropdown) {
          dropdown.hide()
        }
      })
    e.stopPropagation()
  });

  if ($("#diffhtml").length > 0) {
    let oldHtml = document.getElementById("oldhtml");
    let newHtml = document.getElementById("newhtml");
    let diffHtml = document.getElementById("diffhtml");

    let output = htmldiff(oldHtml.innerHTML, newHtml.innerHTML);
    diffHtml.innerHTML = output;
  }

  $(".comment-show").on("click", function () {
    const target_id = $(this).data("targetcommentid");
    $(".comment-popup").removeClass("popup-box-on");
    $(target_id).addClass("popup-box-on");
  });

  $(".comment-hide").on("click", function () {
    $(".comment-popup").removeClass("popup-box-on");
  });

  $('.popup-box.popup-box-on').each(function() {
    console.log('popup-box-on');
    $(this).closest('.ce-block__comment__buttons').addClass('active');
  });
});

class addFields {
  // This executes when the function is instantiated.
  constructor() {
    this.links = document.querySelectorAll('.add_fields')
    this.iterateLinks()
  }

  iterateLinks() {
    // If there are no links on the page, stop the function from executing.
    if (this.links.length === 0) return
    // Loop over each link on the page. A page could have multiple nested forms.
    this.links.forEach(link => {
      link.addEventListener('click', e => {
        this.handleClick(link, e)
      })
    })
  }

  handleClick(link, e) {
    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return
    // Prevent the browser from following the URL.
    e.preventDefault()
    // Save a unique timestamp to ensure the key of the associated array is unique.
    let time = new Date().getTime()
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
    let linkId = link.dataset.id
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    let regexp = linkId ? new RegExp(linkId, 'g') : null
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
    // Add the new markup to the form if there are fields to add.
    newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbo:load', () => new addFields())

$(document).on("click", "a.remove_fields", function (e) {
  e.preventDefault()
  // Find the parent wrapper for the set of nested fields.
  let fieldParent = $(this).closest(".nested-fields")
  // If there is a parent wrapper, find the hidden delete field.
  let deleteField = fieldParent ? $(fieldParent).find('input[type="hidden"]') : null
  // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
  if (deleteField) {
    $(deleteField).val(1);
    $(fieldParent).hide();
  }
});
