import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin"
export default class extends Controller {
  static targets = ["email", "role", "submit"]

  connect() {
    this.checkFields();
  }

  checkFields() {
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (this.emailTarget.value.match(emailFormat) && this.roleTarget.value) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  emailChanged() {
    this.checkFields();
  }

  roleChanged() {
    this.checkFields();
  }
}
