import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import "select2";
// import "select2/dist/css/select2.css";
// import "select2-bootstrap-theme/dist/select2-bootstrap.min.css"; // If you're using the Bootstrap theme

export default class extends Controller {
  connect() {
    this.initializeSelect2();
    //console.log('Select2 controller connected');
  }

  initializeSelect2() {
    //console.log('initializeSelect2');
    $(this.element).find('.select2').each(function() {
      // Destroy any existing select2
      if ($(this).data('select2')) {
        $(this).select2('destroy');
      }

      var multiple = $(this).hasClass('select2-multiple');

      // Initialize select2
      $(this).select2({
        placeholder: $(this).data('placeholder'),
        theme: 'bootstrap-5',
        multiple: multiple,
        width: '100%'
      });

      // Add 'initialized' class
      $(this).addClass('initialized');
    });
  }
}
