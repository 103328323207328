import { Controller } from "@hotwired/stimulus"
// import datatables
import DataTable from "datatables.net-bs5"

export default class extends Controller {
  connect() {
    // $(function() {
    if (!$.fn.DataTable.isDataTable('.table')) {
      let tables = $(this.element).find('.table');
      let title;
      let dt;
      tables.each(function() {
        let table = $(this);
        title = table.data("title") ? table.data("title") : "";
        let searchEnabled = table.data("search-enabled") !== false;  // Default to true if not specified
        dt = table.DataTable({
          dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center title-container"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
          pagingType: "simple",
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          language: {
            emptyTable: $(".dtable").data("empty-table"),
            info: table.data("info"),
            infoEmpty: table.data("info-empty"),
            infoFiltered: table.data("info-filtered"),
            lengthMenu: 'Show _MENU_',
            zeroRecords: table.data("zero-records"),
            paginate: {
              previous: "<i class='bi bi-chevron-left'></i>",
              next: "<i class='bi bi-chevron-right'></i>"
            }
          },
          columnDefs: [
            { orderable: false, targets: -1 },
            { className: "d-flex justify-content-end", targets: -1 }
          ],
          drawCallback: function () {
            $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
            $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
          },
          responsive: true,
          initComplete: function () {
            $(".table thead th:last-child").removeClass("d-flex justify-content-end");
            $(".dataTables_info").addClass("pt-0");
          },
          searching: searchEnabled
        });
      });
      $(".dtable-header .title-container").prepend(`<h5 class="title">${title}</h5>`); // Insert the header tag
      $(".title").text(title);
      window.dt = dt;
      document.addEventListener('turbo:before-cache', function () {
        if ($.fn.dataTable.isDataTable(dt)) {
          dt.destroy();
        }
      });
    }
    // });
  }
}
