import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".drag-handle",
      animation: 150,
      onEnd: this.end.bind(this),
      ghostClass: "draggable-ghost"
    })
  }

  end() {
    Rails.ajax({
      url: this.data.element.dataset.reorder,
      type: "POST",
      data: new URLSearchParams({
        "ids_in_order": this.sortable.toArray().join(",")
      })
    })
  }
}
