import { Controller } from "@hotwired/stimulus"
import plyr from 'plyr'


// Connects to data-controller="video"
export default class extends Controller {
  connect() {
    console.log('video connected')
    const player = new Plyr('.video-player');
    // test if plyr is working
    player.on('play', event => {
      console.log('played')
    })

  }
}
