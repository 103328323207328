import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  connect() {
    //console.log(this.element)
    this.enableDateFields($(this.element));
    this.enableButtons($(this.element));
    this.enableRtwFields($(this.element));
    this.enablePermissionWarning($(this.element));

    $(document).on('sprinkles:update', function(event) {
      this.enableDateFields($(this.element));
    })

    if ($("#salary_bonuses_table").length > 0) {
      // this allows dynamically added fields to be datepickered
      $(document).on('focus',"input.single-daterange", function(event) {
        $(event.target).daterangepicker({
          showDropdowns: true,
          minYear: new Date().getFullYear() - 100,
          maxYear: new Date().getFullYear() + 5,
          singleDatePicker: true,
          autoUpdateInput: false,
          autoApply: true,
          locale: {
            firstDay: 1,
            cancelLabel: 'Clear',
            format: 'DD/MM/YYYY',
          }
        });

        $(event.target).on('apply.daterangepicker', function(ev, picker) {
          $(event.target).val(picker.startDate.format('DD/MM/YYYY')).trigger('input');
        });

        $(event.target).on('cancel.daterangepicker', function(ev, picker) {
          $(event.target).val('').trigger('input');
        });

        $(event.target).on('input', function() {
          if ($(event.target).val() !== '') {
            $(event.target).closest('.date-input').find('.clear-input').show();
          } else {
            $(event.target).closest('.date-input').find('.clear-input').hide();
          }
        }).trigger('input'); // Trigger the input event initially to set the correct visibility

        $(event.target).closest('.date-input').find('.clear-input').on('click', function() {
          event.preventDefault();
          var $dateField = $(event.target).closest('.date-input').find('input');
          $dateField.val(null);
          $(this).closest('.date-input').find('input.single-daterange').val('').trigger('input');
        });
      });
    }
    $('.business_type_other_row').hide();
    $('.select2-multiple').on('change', function() {
      if ($(this).val().includes('Other')) {
        $('.business_type_other_row').show();
      } else {
        $('.business_type_other_row').hide();
      }
    });

  }

  enablePermissionWarning($scope) {
    // if select.role-selection is Account Owner, Director/ Owner/ Partner, General Manager, HR Manager, H&S Manager, Head of Department, Manager should warning
    // div.role-selection-warning
    $scope.find('.role-selection').on('change', function () {
      $scope.find('.role-selection option:selected').each(function () {
        const selected_option = $(this).html()
        if (['Account Owner', 'Director/ Owner/ Partner', 'General Manager', 'HR Manager', 'H&amp;S Manager', 'Head of Department', 'Manager'].includes(selected_option)) {
          $scope.find('.role-selection-warning').show();
        } else {
          $scope.find('.role-selection-warning').hide();
        }
      });
    });

    // when input checkbox .permission-checkbox is check, double confirm that they want to check it
    $scope.find('.manage_med_q_and_inductions_permission_checkbox').on('change', function () {
      if ($(this).is(':checked')) {
        if (!confirm('This permission gives access to sensitive information. Are you sure?')) {
          $(this).prop('checked', false);
        }
      }
    });

  }

  enableButtons($scope) {
    // safari does what we expected by default, but chrome wasn't checking the radio or checkbox.
    $scope.find('.btn-toggle button').on('click', function(event){

      // we have to stop safari from doing what we originally expected.
      event.preventDefault();

      // then we need to manually click the hidden checkbox ourselves.
      $(event.target).closest('.btn-toggle').find('input[type=radio], input[type=checkbox]').click();

    });
  }

  enableRtwFields($scope) {
    if ($scope.find("#return_to_work_absence_start, #return_to_work_return_date, #absence_length").length === 3) {

      let absenceStart = $('#return_to_work_absence_start')[0].value
      let absenceEnd = $('#return_to_work_return_date')[0].value

      function calcAbsenceLength() {
          if (absenceStart && absenceEnd) {
              const momentStart = moment(absenceStart, 'DD/MM/YYYY')
              const momentEnd = moment(absenceEnd, 'DD/MM/YYYY')
              $("#absence_length")[0].innerHTML = moment.preciseDiff(momentStart, momentEnd)
          }
      }

      calcAbsenceLength();

      $scope.find('#return_to_work_absence_start').on('apply.daterangepicker', function(ev, picker) {
          absenceStart = picker.startDate.format('DD/MM/YYYY');
          calcAbsenceLength();
      });

      $scope.find('#return_to_work_return_date').on('apply.daterangepicker', function(ev, picker) {
          absenceEnd = picker.startDate.format('DD/MM/YYYY');
          calcAbsenceLength();
      });
  }
  }

  enableDateFields($scope) {
    function clearDate(event) {

      // don't submit the form.
      event.preventDefault();

      // find a bunch of relevant elements.
      var $dateField = $(event.target).closest('.date-input').find('input');

      // clear the cloudinary id.
      $dateField.val(null);

      $(this).closest('.date-input').find('input.single-daterange').val('').trigger('input');

    }
    $(function() {
      $scope.find('input.single-daterange').not('.single-daterange-with-time').not('.future-only').not('.past-only').each(function() {
        $(this).daterangepicker({
          showDropdowns: true,
          minYear: new Date().getFullYear() - 100,
          maxYear: new Date().getFullYear() + 5,
          singleDatePicker: true,
          autoUpdateInput: false,
          autoApply: true,
          locale: {
            firstDay: 1,
            cancelLabel: 'Clear',
            format: 'DD/MM/YYYY',
          }
        });

        $(this).on('apply.daterangepicker', function(ev, picker) {
          $(this).val(picker.startDate.format('DD/MM/YYYY')).trigger('input');
        });

        $(this).on('cancel.daterangepicker', function(ev, picker) {
          $(this).val('').trigger('input');
        });

        $(this).on('input', function() {
          if ($(this).val() !== '') {
            $(this).closest('.date-input').find('.clear-input').show();
          } else {
            $(this).closest('.date-input').find('.clear-input').hide();
          }
        }).trigger('input'); // Trigger the input event initially to set the correct visibility
      });

      $scope.find('input.single-daterange.past-only').not('.single-daterange-with-time').each(function() {
        $(this).daterangepicker({
          showDropdowns: true,
          maxDate: new Date(),
          minYear: new Date().getFullYear() - 100,
          maxYear: new Date().getFullYear(),
          singleDatePicker: true,
          autoUpdateInput: false,
          autoApply: true,
          locale: {
            firstDay: 1,
            cancelLabel: 'Clear',
            format: 'DD/MM/YYYY',
          }
        });

        $(this).on('apply.daterangepicker', function(ev, picker) {
          $(this).val(picker.startDate.format('DD/MM/YYYY')).trigger('input');
        });

        $(this).on('cancel.daterangepicker', function(ev, picker) {
          $(this).val('').trigger('input');
        });

        $(this).on('input', function() {
          if ($(this).val() !== '') {
            $(this).closest('.date-input').find('.clear-input').show();
          } else {
            $(this).closest('.date-input').find('.clear-input').hide();
          }
        }).trigger('input'); // Trigger the input event initially to set the correct visibility
      });

      $scope.find('input.single-daterange.future-only').not('.single-daterange-with-time').each(function() {
        $(this).daterangepicker({
          showDropdowns: true,
          minDate: new Date(),
          minYear: new Date().getFullYear(),
          maxYear: new Date().getFullYear() + 5,
          singleDatePicker: true,
          autoUpdateInput: false,
          locale: {
            firstDay: 1,
            cancelLabel: 'Clear',
            format: 'DD/MM/YYYY',
          }
        });

        $(this).on('apply.daterangepicker', function(ev, picker) {
          $(this).val(picker.startDate.format('DD/MM/YYYY')).trigger('input');
        });

        $(this).on('cancel.daterangepicker', function(ev, picker) {
          $(this).val('').trigger('input');
        });

        $(this).on('input', function() {
          if ($(this).val() !== '') {
            $(this).closest('.date-input').find('.clear-input').show();
          } else {
            $(this).closest('.date-input').find('.clear-input').hide();
          }
        }).trigger('input'); // Trigger the input event initially to set the correct visibility
      });

      $scope.find('input.single-daterange-with-time').not('.past-only').daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        locale: {
          firstDay: 1,
          cancelLabel: 'Clear',
          format: 'DD/MM/YYYY HH:mm',
        }
      });
      $scope.find('input.single-daterange-with-time.past-only').daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        maxDate: new Date(),
        locale: {
          firstDay: 1,
          cancelLabel: 'Clear',
          format: 'DD/MM/YYYY HH:mm',
        }
      });

      $scope.find('input.single-daterange-with-time').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY HH:mm'));
      });

      $scope.find('input.single-daterange-with-time').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
      });

      $scope.on('click', '.clear-input', function() {
        $(this).closest('.date-input').find('input.single-daterange').val('').trigger('input');
      });

      $scope.find(".date-input button.clear").click(clearDate);
    });

    $scope.find('.app-datepicker').not('.future-only').not('.past-only').daterangepicker({
      autoApply: true,
      autoUpdateInput: false,
      singleDatePicker: true,
      showDropdowns: false,
      locale: {
        format: 'DD/MM/YYYY',
        firstDay: 1,
      },
    })
    $scope.find('.app-datepicker.future-only').daterangepicker({
      autoApply: true,
      autoUpdateInput: false,
      singleDatePicker: true,
      showDropdowns: false,
      minDate: new Date(),
      locale: {
        format: 'DD/MM/YYYY',
        firstDay: 1,
      },
    })
    $scope.find('.app-datepicker.past-only').daterangepicker({
      autoApply: true,
      autoUpdateInput: false,
      singleDatePicker: true,
      showDropdowns: true,
      maxDate: new Date(),
      locale: {
        format: 'DD/MM/YYYY',
        firstDay: 1,
      },
    })

    $scope.find('.app-datepicker').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY'));
    });
  }
}
