import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown-search"
export default class extends Controller {
  static targets = ["input", "item"]

  connect() {
    console.log("Dropdown search controller connected")
  }

  search() {
    const query = this.inputTarget.value.toLowerCase()

    this.itemTargets.forEach((item) => {
      const text = item.textContent.toLowerCase()
      item.style.display = text.includes(query) ? "" : "none"
    })
  }
}
