import { Controller } from "@hotwired/stimulus"
// import datatables
import DataTable from "datatables.net-bs5"
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.html5.js';

export default class extends Controller {
  connect() {
    if ($(this.element).find('.dt-file').length > 0) {
      let simpleDt = $(this.element).find('.dt-file').dataTable({
        dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end">>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
        pagingType: "simple",
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
        stateSave: true,
        responsive: true,
        drawCallback: function () {
          $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
          $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
        },
        language: {
          emptyTable: $(".table").data("empty-table"),
          info: $(".table").data("info"),
          infoEmpty: $(".table").data("info-empty"),
          infoFiltered: $(".table").data("info-filtered"),
          lengthMenu: 'Show _MENU_',
          zeroRecords: $(".table").data("zero-records"),
          paginate: {
            previous: "<i class='bi bi-chevron-left'></i>",
            next: "<i class='bi bi-chevron-right'></i>"
          }
        },
      });
    if (simpleDt) {
      $(this.element).find(".dataTables_info").addClass("pt-0");
      window.simpleDt = simpleDt;

      // Unload datatable when turbolinks visit
      $(document).on('turbo:before-visit', function () {
        simpleDt.fnDestroy();
      });

      document.addEventListener('turbo:before-cache', function () {
        // simpleDt.destroy(); // Use destroy instead of fnDestroy
      });
    }
    } else if ($(this.element).find('.dt-advanced').length > 0) {
      let dt = $(this.element).find('.dt-advanced').dataTable({
        dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end"f>>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
        pagingType: "simple",
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
        stateSave: true,
        language: {
          emptyTable: $(".table").data("empty-table"),
          info: $(".table").data("info"),
          infoEmpty: $(".table").data("info-empty"),
          infoFiltered: $(".table").data("info-filtered"),
          lengthMenu: 'Show _MENU_',
          zeroRecords: $(".table").data("zero-records"),
          paginate: {
            previous: "<i class='bi bi-chevron-left'></i>",
            next: "<i class='bi bi-chevron-right'></i>"
          }
        },
        columnDefs: [
          { orderable: false, targets: -1 },
          { className: "text-end", targets: -1 }
        ],
        drawCallback: function () {
          $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
          $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
        },
        responsive: true,
        initComplete: function () {
          // remove class from last th column
          $(".table thead th:last-child").removeClass("text-end");
        }

      });

      if (dt) {
        $(this.element).find(".dataTables_info").addClass("pt-0");
        window.dt = dt;
        // unload datatable when turbolinks visit
        $(document).on('turbo:before-visit', function () {
          dt.fnDestroy();
        });
        document.addEventListener('turbo:before-cache', function () {
          // dt.fnDestroy(); // Use destroy instead of fnDestroy
        });
      }
    } else if ($(this.element).find('.dt-user-analysis').length > 0) {
      let exportDt = $(this.element).find('.dt-user-analysis').dataTable({
        dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end">>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
        pagingType: "simple",
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
        pageLength: 25,
        stateSave: true,
        stateLoadParams: function (settings, data) {
          data.start = 0;
          data.length = 25;
        },
        language: {
          emptyTable: $(".table").data("empty-table"),
          info: $(".table").data("info"),
          infoEmpty: $(".table").data("info-empty"),
          infoFiltered: $(".table").data("info-filtered"),
          lengthMenu: 'Show _MENU_',
          zeroRecords: $(".table").data("zero-records"),
          paginate: {
            previous: "<i class='bi bi-chevron-left'></i>",
            next: "<i class='bi bi-chevron-right'></i>"
          }
        },

        drawCallback: function () {
          $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
          $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
        }
      });
      if (exportDt) {
        $(this.element).find(".dataTables_info").addClass("pt-0");
        window.exportDt = exportDt;
        // unload datatable when turbolinks visit
        $(document).on('turbo:before-visit', function () {
          exportDt.fnDestroy();
        });
        document.addEventListener('turbo:before-cache', function () {
          // exportDt.fnDestroy(); // Use destroy instead of fnDestroy
        });
      }
    } else if ($(this.element).find('.table').length > 0) {
      let table = $(this.element).find('.table').dataTable({
        dom: '<"dtable-header"<"row"<"col-sm-12 col-md-6 d-flex align-items-center"><"col-sm-12 col-md-6 d-flex align-items-center justify-content-end">>>' + '<"dtable-container"tr>' + '<"dtable-footer"<"row align-items-center"<"col-sm-12 col-md-5"l><"col-sm-12 col-md-7"<"d-flex align-items-center justify-content-end"<"me-3"i><p>>>>>',
        pagingType: "simple",
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
        stateSave: true,
        responsive: true,
        drawCallback: function () {
          $(".dataTables_paginate > ul").removeClass("pagination").addClass("dt-pagination");
          $(".dataTables_paginate > ul > li > a").removeClass("page-link").addClass("dt-pagination-link");
        },
        language: {
          emptyTable: $(".table").data("empty-table"),
          info: $(".table").data("info"),
          infoEmpty: $(".table").data("info-empty"),
          infoFiltered: $(".table").data("info-filtered"),
          lengthMenu: 'Show _MENU_',
          zeroRecords: $(".table").data("zero-records"),
          paginate: {
            previous: "<i class='bi bi-chevron-left'></i>",
            next: "<i class='bi bi-chevron-right'></i>"
          },
        },
        columnDefs: [
          { orderable: false, targets: -1 },
          { className: "text-end", targets: -1 }
        ],
      });
      if (table) {

        $(this.element).find(".dataTables_info").addClass("pt-0");
        window.table = table;

        // Unload datatable when turbolinks visit
        $(document).on('turbo:before-visit', function () {
          table.fnDestroy();
        });

        document.addEventListener('turbo:before-cache', function () {
          // table.destroy();
        });
      }
    }

  }
}
