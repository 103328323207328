import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simple-search"
export default class extends Controller {
  static targets = ["input", "item"]

  connect() {
    console.log("Simple search controller connected")
    this.inputTarget.addEventListener('input', this.search.bind(this))
  }

  search() {
    const query = this.inputTarget.value.toLowerCase()

    this.itemTargets.forEach((item) => {
      const text = item.textContent.toLowerCase()
      if (query == '' || text.includes(query)) {
        item.style.setProperty('display', 'flex', 'important');
        $(item).addClass('search-match');
        $(item).removeClass('not-search-match');
      } else {
        item.style.setProperty('display', 'none', 'important');
        $(item).addClass('not-search-match');
        $(item).removeClass('search-match');
      }
      console.log('items', $(item).parent().children('.searchable-item').length)
      console.log('hidden', $(item).parent().children('.searchable-item.not-search-match').length)
      if ($(item).parent().children('.searchable-item').length === $(item).parent().children('.searchable-item.not-search-match').length) {
        $(item).parent().hide();
      } else {
        $(item).parent().show();
      }
    })
  }
}
